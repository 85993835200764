<template>
    <modal :clickToClose="false" :delay="0" :height="'auto'" :scrollable="true" :adaptive="true" :maxHeight="100" :maxWidth="900" :classes="['v--modal']" class="pozice-modal" width="95%" name="pozice-modal">
        <div class="errorbox"  v-if="errors.length">
            <p v-for="error in errors" :key="error.index">{{error}}</p>
         </div>
        <div class="pozice-modal__container">
            <div class="pozice-modal__krok">Nastavení profilu 1 / 1</div>
            <div class="pozice-modal__nadpis">Uveďte své pracovní zařazení a Vaše primární pracoviště (katedru).</div>
            <div class="pozice-modal__info-wrapper">
                <Info description="Vyberte Vaší současnou roli." />
                <label for="pozice" class="program-modal__label">Vyberte Vaší současnou roli</label>
            </div>
            <select class="pozice-modal__select" v-model="pozice" @focus="errors=[]">
                <option disabled selected hidden :value="null" >Vyberte Vaší současnou roli</option>
                <option v-for="r in role" :key="r.id" :value="r.id">{{ r.nazev }}</option>
            </select>
            <div class="pozice-modal__info-wrapper">
                <Info description="prof. = profesor, doc. = docent, Ph.D./CSc. = odboný asistent, akademický pracovník bez vědecké hodnosti = asistent, student doktorského studia = doktorand" />
                <label for="katedra" class="program-modal__label">Vyberte Vaší katedru</label>
            </div>
            <select class="pozice-modal__select" v-model="katedra" @focus="errors=[]">
                <option disabled selected hidden :value="null">Vyberte Vaší katedru</option>
                <option v-for="k in katedry" :key="k.id" :value="k.id">{{ k.zkratka }}</option>
            </select>
            <button class="pozice-modal__confirm" @click="confirm">Potvrdit</button>
        </div>
    </modal>
    
</template>

<script>
import Info from './Info.vue'

export default {
    name: 'vyberPozice',
    components: {
        Info
    },
    data() {
        return {
            role: [],
            katedry: [],
            errors: [],
            pozice: null,
            katedra: null
        }
    },
    methods: {
        showModal() {
            this.$modal.show('pozice-modal', {
                height: 'auto'
            });
        },
        confirm() {
            if (this.pozice == null || this.katedra == null) {

                if(this.pozice == null && this.katedra == null) {
                    this.errors.push("Zvolte prosím svou pozici a katedru.");
                }

                else if(this.katedra == null) {
                    this.errors.push("Zvolte prosím svou katedru.");
                }

                else {
                    this.errors.push("Zvolte prosím svou pozici.");
                }
                
            }
            else if (this.pozice != null && this.katedra != null) {
                var data = {
                    pozice: this.pozice,
                    katedra: this.katedra
                };
                this.$http
                .post('/api/user/set/role', data)
                .then((response => {
                    window.console.log(response.data)
                    if (response.data == 'success') {
                        this.$store.dispatch('getUser');
                        this.$modal.hide('pozice-modal');
                        this.$emit('close')
                    }
                }));
                
            }
        }
    },
    mounted() {
        this.showModal();
        this.$http
        .get('/api/role/all')
        .then((response) => {
            this.role = response.data
            window.console.log(response.data)
        });
        this.$http
        .get('/api/katedry/all')
        .then((response) => {
            this.katedry = response.data
            window.console.log(response.data)
        })
    }
}
</script>