<template>
  <div class="home">
    <VyberPozice v-if="$store.state.user.id_role == 0" />

    <DeleteProgramModal v-if="smazatProgram" v-on:close="smazatProgram = false;" />

    
    
 


 
    <ProgramModal
      v-if="vytvoritProgram || upravitProgram"
      :edit="upravitProgram"
      :id="upravitId"
      v-on:close="zavriModal"
    />








    <Settings v-if="openSettings" v-on:close="closeSettingsWindow()"  />


    <div
      class="blur-content-animation"
      :class="{'blur-content': this.vytvoritProgram || this.upravitProgram || this.smazatProgram || this.openSettings}"
    >
      <HeaderUser v-on:openSettings="openSettings = true;" />

      <div class="program_box" v-if="$store.state.user.id_role != 0">
        <VytvoritProgram v-on:vytvorit="vytvoritProgram = true" />

        <Program
          v-for="program in $store.state.programy"
          :key="program.id"
          @deleteProgramModal="deleteProgramModal(program.id_program)"
          @upravit="upravit(program.id_program)"
          :id="program.id_program"
          :nazev="program.nazev"
          :status="program.status"
          :datum_zalozeni="program.datum_zalozeni"
          :cena="program.financni_hodnota"
          :veda="program.veda"
          :oblast="program.oblast"
          :oblast_name="program.oblast_name"
          :nazev_statusu="program.nazev_statusu"
          :nazev_veda="program.nazev_veda"
        />
      </div>
    </div>


     <!-- <div><p style="text-align: center; font-size: 22px !important;;  margin:15px 0 0 0 !important;">Seznam programů pro rok 2021 bude vyhlášen 1. února 2021.</p></div> -->





  </div>
</template>

<script>
import Program from "./components/Program.vue";
import HeaderUser from "./components/HeaderUser.vue";
import VyberPozice from "./components/VyberPozice.vue";
import VytvoritProgram from "./components/VytvoritProgram.vue";
import ProgramModal from "./components/ProgramModal.vue";
import DeleteProgramModal from "./components/DeleteProgramModal.vue";
import Settings from "./components/Settings.vue";

export default {
  name: "home",
  components: {
    Program,
    HeaderUser,
    VyberPozice,
    DeleteProgramModal,
    VytvoritProgram,
    ProgramModal,
    Settings
  },
  data() {
    return {
      vytvoritProgram: false,
      upravitProgram: false,
      upravitId: 0,
      smazatId: false,
      smazatProgram: false,
      openSettings: false
    };
  },

  methods: {
    upravit(id) {
      this.upravitProgram = true;
      this.upravitId = id;
    },
    deleteProgramModal(id) {
      this.smazatProgram = true;
      this.smazatId = id;
      window.console.log(this.smazatId);
    },
    zavriModal() {
      setTimeout(
        function() {
          this.upravitProgram = false;
          this.vytvoritProgram = false;
          this.upravitId = false;
        }.bind(this),
        15
      );
    },
        closeSettingsWindow() {
      this.openSettings = false;
    }
  },

  mounted() {
    this.$gtag.pageview({
      page_path: "/"
    });
  }
};
</script>