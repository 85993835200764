<template>
  <modal :clickToClose="false" :delay="0" :height="'auto'" :scrollable="true" :adaptive="true" :maxHeight="100" :maxWidth="900" :classes="['v--modal']" class="pozice-modal" width="95%" name="pozice-modal">
    <div class="program-modal-wrapper">
      <button @click="hideModal" class="program-modal-wrapper__close"></button>
      <div class="program-modal nacteno">
        <div class="pozice-modal__nadpis">Opravdu si přejete odebrat vybraný program?</div>
        <button class="pozice-modal__confirm" @click="odstranit">Odstranit</button>
      </div>
    </div>
  </modal>
    
</template>

<script>
export default {
  name: "DeleteProgramModal",
  methods: {
    showModal() {
      this.$modal.show("pozice-modal", {
        height: "auto"
      });
    },
    hideModal() {
      this.$emit("close");
      this.$modal.hide("program-modal");
    },
    odstranit() {
      this.$gtag.event("Smazání programu", {
        event_category: "Práce s aplikací"
      });
      this.$modal.hide("program-modal");
      this.$http
        .post("/api/program/delete", { id: this.$parent.smazatId })
        .then(response => {
          if (response.data == "success") {
            this.$store.dispatch("getProgramy");
            this.$emit("close");
          }
        });
    }
  },
  mounted() {
    this.showModal();
  }
};
</script>