<template>
   
   <div>


    <div class="vytvorit-program">
   

        <div class="vytvorit-program__content">
       <button class="vytvorit-program__content__button" @click="$emit('vytvorit')">Přidat program</button> 
        </div>

    </div>
 


   </div>





    
</template>

<script>
export default {
    name: 'vytvoritProgram'
}
</script>