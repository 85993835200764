<template>
    <div class="info" @mouseover="hover(); hovering=true" @mouseleave="hovering = false; showInfo = false">
        <div class="info__ikona">i</div>
        <div v-if="showInfo" class="info__popis">
           <p>{{ description }}</p>
        </div>
    </div>
</template>

<script>
//pouziti v ProgramModal <Info description="  TEXT POPISU  " />

export default {
    name: "info",
    props: {
        description: String
    },
    data() {
        return {
            showInfo: false,
            hovering: false
        }
    },
    methods: {
        hover() {
            window.setTimeout(() => {
                if (this.hovering) {
                    this.showInfo = true
                }
            }, 900)
        }
    }
}
</script>
