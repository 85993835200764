<template>
  <div class="o-aplikaci">
    <Settings v-if="openSettings" v-on:close="closeSettingsWindow()"  />

    <div
      class="blur-content-animation"
      :class="{'blur-content': this.openSettings}"
    >

      <HeaderUser v-on:openSettings="openSettings = true;" />

      <div class="o-aplikaci__text">
        <div class="column1">
          <h2>Co to je POKR a k čemu slouží</h2>
          <p>
            <strong>P</strong>rogram
            <strong>O</strong>sobního a
            <strong>K</strong>valifikačního
            <strong>R</strong>ozvoje 
            (<strong>POKR</strong>) slouží k podpoře tvůrčích aktivit pracovníků FIS. V jeho rámci je možné zažádat o podporu těchto aktivit, a to nejdéle na období jednoho kalendářního roku.
          </p>

          <p>Při prvním přihlášení zadejte svoji pracovní roli (doktorand, asistent, odborný asistent, docent nebo profesor) a katedru, na níž máte majoritní úvazek.</p>
          <p>Při přihlášení do programu zadejte oblast Vašich aktivit („Tvůrčí činnosti“ nebo „Pedagogika“), a potom pokračujte v zadání konkrétního programu.</p>

        
        
        <h3 class="nadpis">Tvůrčí činnost</h3>
        <p>O podporu v rámci programu <strong>“Publikační činnost”</strong> na rok <strong>2024</strong> je možné žádat <strong>od 1. 2. do 22. 2. do 23:59.</strong> U ostatních programů celoročně. Podporu je možné pro tento rok získat v následujících programech spadajících do Tvůrčí činnosti:</p>
        </div>

        <div class="column2">
          <p>
            <strong>Publikační činnost</strong> - Program je určen pro zájemce, kteří by chtěli podpořit výhradně svoje publikační aktivity. Umožní požádat o podporu pro zamýšlené publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2022 v prvním až třetím kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů). Fakulta zjišťuje, ke které z vymezených fakultních domén se publikační výstup vztahuje: <br>

            <ol start="1" class="about">
              <li >Ekonomické aspekty stárnutí</li>
              <li>Demografické studie</li>
              <li>Lidský kapitál v souvislostech, financování vysokého školství</li>
              <li>Sociodemografické studie</li>
              <li>Statistické zachycení a modelování ekonomických procesů</li>
              <li>Optimalizační modely v podnikové sféře</li>
              <li>Analýza transakčních dat</li>
              <li>Speciální data v ekonometrii</li>
              <li>Sémantický web, ontologické inženýrství - vzory v ontologiích</li>
              <li>Aplikace datových věd</li>
              <li>Srozumitelnost a interpretovatelnost data miningových modelů</li>
              <li>Data Management (Správa dat)</li>
              <li>Dotazníková šetření v socioekonomické oblasti</li>
              <li>Modely hodnocení efektivnosti a výkonnosti</li>
              <li>Řízení a vývoj IS/ICT</li>
              <li>Aplikované prediktivní modely</li>
              <li>Sémantický web, ontologické inženýrství - znalostní grafy, grafové databáze, vizualizace grafových dat.</li>
              <li>Machine learning v cloudu a automatizace ML</li>
              <li>Analýza dezinformací v textech</li>
              <li>Efektivita multimediální komunikace (aplikace v marketingové komunikaci a prezentaci entit)</li>
              <li>Efektivita UX a UI</li>
              <li>Multimédia ve vzdělávání (video, grafika, podcast)</li>
              <li>Vícerozměrné metody</li>
              <li>Information Security</li>
              <li>Human-Computer Interaction (Interakce člověk-počítač)</li>
            </ol>
          </p>

          <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>





       <p>
            <strong>Vědecká monografie</strong> -
             Podpořena bude celá publikace (ne jednotlivé kapitoly) monografického charakteru (splňuje podmínky monografie dle metodiky 17+) v prestižním zahraničním nakladatelství ve světovém jazyce. Podmínkou pro získání podpory je uzavřená smlouva s tímto nakladatelstvím.
            </p>

        






        </div>

        <div class="column2">
          <p>
            <strong>Jmenovací řízení</strong> -
            Program slouží k podpoře přípravy uchazeče o akademickou hodnost profesor. Její součástí je podpora publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2022 v prvním či druhém kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů) a relevantní k oblasti jmenovacího řízení. Navíc musí předkladatel mít minimálně poloviční podíl na podporovaném článku.
            Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku. Na konci roku předkládá pracovník jako další výstup kompletní dokumentaci k zahájení jmenovacího řízení.
          </p>



          <p><strong>Habilitační řízení</strong>- Program slouží k podpoře přípravy uchazeče o hodnost docent. Její součástí je podpora publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2022 v prvním či druhém kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů) a relevantní k oblasti habilitačního řízení. Navíc musí předkladatel mít minimálně poloviční podíl na podporovaném článku. Na konci roku předkládá pracovník jako další výstupy 80 % habilitační práce a dokumentaci k zahájení habilitačního řízení.
            Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>



          <p>
         <strong>Hostující profesor</strong> - 
          Program slouží k podpoře internacionalizace FIS. Cílem je podpořit činnost zahraničních pedagogů na FIS s přesahem do vědecko-výzkumné činnosti. Ve formuláři žadatel vyplní informaci o působení hostujícího profesora a o jeho vědecko-výzkumných aktivitách na FIS. Na tyto činnosti mu bude přidělena finanční podpora.
         </p>


          <p><strong>Podpora podání nového externího projektu </strong>- Program slouží k podpoře přípravy nového projektu, který bude podávat pracovník FIS (případně s kolektivem). Při podávání žádosti uchazeč vyplní název projektu, agenturu, k níž projekt chce podat, spolupracovníky (maximální počet pracovníků na projektu jsou čtyři) a jejich podíl na projektu.</p>
          <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>



          <p><strong>Podpora podání opraveného externího projektu</strong> - Program slouží k podpoře přípravy již jednou podaného a neúspěšného projektu, který bude podávat pracovník FIS (případně s kolektivem). Při podávání žádosti uchazeč vyplní název projektu, agenturu, k níž projekt podává, spolupracovníky (maximální počet pracovníků na projektu jsou čtyři) a jejich podíl na projektu.</p>
          <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>

        </div>


<!--
 <div class="column1">
      <h3 class="nadpis">Pedagogika</h3>
        <p><strong>Tvorba e-learningového modulu</strong> - Program slouží k rozvoji výuky prostřednictvím tvorby a aplikace e-learningového modulu, kterým může být kombinace vzdělávacího videa, komentovaného záznamu práce s aplikací, audio nahrávek, pracovních listů nebo jiných podpor, které zvýší efektivitu studia během přímé i nepřímé výuky. Pracovník se zadáním do systému POKR zavazuje k přípravě podkladů a účasti na realizaci. Technickou realizaci e-learningu (natáčení videa, audia, grafické práce apod.) zajistí Katedra multimédií, jejíž pověřený pracovník bude zároveň od okamžiku schválení projektu průběžně spolupracovat a konzultovat přípravu. Při podávání žádosti uchazeč vyplní studijní předmět, pro který by chtěl e-learningové podpory připravit a rámcovou představu o podobě e-learningového modulu. Součástí programu je účast na školeních pro různé dovednosti potřebné pro přípravu e-learningu.</p>
  </div>
-->









        <div class="column1">
          <a
            class="buglist"
            href="https://docs.google.com/spreadsheets/d/1LN7NunG0X1EX51SYkmkrJVEdOXLwGBb8WMthnEMUAzY/edit?usp=sharing"
            target="_blank"
          >Buglist</a>
          <p class="verze">Verze aplikace 1.0.8.</p>
        </div>

        <div class="clearfix"></div>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderUser from "./components/HeaderUser.vue";
import Settings from "./components/Settings.vue";

export default {
  name: "O_aplikaci",
  components: {
    HeaderUser,
    Settings
  },
  mounted() {
    this.$gtag.pageview({
      page_path: "/o"
    });
  },
  data() {
    return {
      openSettings: false
    }
  },
  methods: {
    closeSettingsWindow() {
      this.openSettings = false;
    }
  }
};
</script>
