<template>
  <modal :clickToClose="false" :delay="0" :height="'auto'" :scrollable="true" :adaptive="true" :maxHeight="100" :maxWidth="900" :classes="['v--modal']" class="pozice-modal" width="95%" name="pozice-modal">
    <div class="program-modal-wrapper">
      <button @click="hideModal" class="program-modal-wrapper__close"></button>
      <div class="program-modal nacteno">
        <div class="pozice-modal__nadpis">Nastavení</div>
        <label
          for="email"
          class="program-modal__label"
        >Vaše emailová adresa</label>
        <input
          type="text"
          name="email"
          v-model="email"
          placeholder="Vaše emailová adresa"
          class="program-modal__settings__input"
        />
        <button class="pozice-modal__confirm" style="width: 241px;" @click="potvrdit()">Potvrdit změny</button>
      </div>
    </div>
  </modal>
    
</template>

<script>
export default {
  name: "Settings",
  methods: {
    showModal() {
      this.$modal.show("pozice-modal", {
        height: "auto"
      });
    },
    hideModal() {
      this.$emit("close");
      this.$modal.hide("program-modal");
    },
    potvrdit() {
      if(this.email.includes("@") && this.email.includes(".")) {
      this.$gtag.event("Změna nastavení", {
        event_category: "Práce s aplikací"
      });
      this.$modal.hide("program-modal");
      this.$http
        .post("/api/user/changeSettings", { email: this.email })
        .then(response => {
          if (response.data == "success") {
            this.$store.dispatch("getUser");
            this.$emit("close");
          }
        });
      }
      else {
        alert("Prosím vyplňte platnou emailovou adresu.")
      }
    }
  },
  mounted() {
    this.showModal();
  },
  data() {
    return {
      email: this.$store.state.user.email
    }
  }
};
</script>