<template>
  <div class="admin">

    <div :class="{'blur-content': showModal}">

      <HeaderAdmin />

      <div class="admin__label">
        <!-- divy jsou pro zalamovani inputu -->
        <div>
          <div>
            <label for="oblast">Oblast</label>
            <select
              v-model="filterOblast"
              id="oblast"
            >
              <option
                selected
                value="all"
              >Všechny</option>
              <option
                v-for="oblast in oblasti"
                :key="oblast"
                :value="oblast"
              >{{ oblast }}</option>
            </select>
          </div>

          <div>
            <label for="program">Programy</label>
            <select
              v-model="filterProgram"
              id="program"
            >
              <option
                selected
                value="all"
              >Všechny</option>
              <option
                v-for="program in programyDruhy"
                :key="program"
                :value="program"
              >{{ program }}</option>
            </select>
          </div>

          <div>
            <label for="jmeno">Jméno </label>
            <select
              v-model="filterJmeno"
              id="jmeno"
            >
              <option
                selected
                value="all"
              >Všechny</option>
              <option
                v-for="jmeno in jmena"
                :key="jmeno"
                :value="jmeno"
              >{{ jmeno }}</option>
            </select>
          </div>
        </div>

        <div>
          <div>
            <label for="status">Status </label>
            <select
              v-model="filterStatus"
              id="status"
            >
              <option
                selected
                value="all"
              >Všechny</option>
              <option
                v-for="program in statusy"
                :key="program.id"
                :value="program.status"
              >{{ program.status_nazev }}</option>
            </select>
          </div>

          <div>
            <label for="katedra">Katedra </label>
            <select
              v-model="filterKatedra"
              id="katedra"
            >
              <option
                selected
                value="all"
              >Všechny</option>
              <option
                v-for="katedra in katedry"
                :key="katedra"
                :value="katedra"
              >{{ katedra }}</option>
            </select>
          </div>

        </div>

      </div>

      <AdminProgram
        v-for="program in programyVisible"
        :key="program.id_program"
        :id="program.id_program"
        :cele_jmeno="program.cele_jmeno"
        :nazev="program.program_nazev"
        :oblast="program.oblast_name"
        :id_nazev="program.id_nazev"
        :datum="program.datum_zalozeni"
        :status="program.id_status"
        :status_nazev="program.status"
        :financni_hodnota="program.financni_hodnota"
        @reload="getProgramy()"
        v-on:show_modal="Show_modal"
      />

      <JsonExcel :data="excel_export">
        <div class="export_icon_wrapper">
          <img class="export_icon">
        </div>
      </JsonExcel>
    </div>

    <AdminModal
      v-if="showModal == true"
      :id="id"
      :edit='upravitProgram'
      v-on:zavrit="Hide_modal"
      :clickToClose="false"
      transition="nice-modal-fade"
      :delay="0"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :maxHeight="100"
      name="AdminModal"
    />
  </div>

</template>




<script>
import HeaderAdmin from "./components/HeaderAdmin.vue";
import AdminProgram from "./components/AdminProgram.vue";
import AdminModal from "./components/AdminModal.vue";
import JsonExcel from "vue-json-excel";

export default {
  name: "admin",
  components: {
    HeaderAdmin,
    AdminProgram,
    AdminModal,
    JsonExcel
  },

  data() {
    return {
      id: "",
      nazev: null,
      upravitProgram: false,
      showModal: null,
      programy: [],
      filterProgram: "all",
      filterJmeno: "all",
      filterStatus: "all",
      filterKatedra: "all",
      filterOblast: "all",
      id_projekt_nazev: "",
      podpory: [],
      osobni_rust: []
    };
  },

  methods: {
    Show_modal(id) {
      this.id = id;

      setTimeout(() => {
        this.showModal = true;
      }, 500);
    },

    replaceDiacritics(text) {
      const diacr = "ÁÄČÇĎÉĚËÍŇÓÖŘŠŤÚŮÜÝŽáäčçďéěëíňóöřšťúůüýž";
      const diacrSubs = "AACCDEEEINOORSTUUUYZaaccdeeeinoorstuuuyz";

      if (text.length < diacr.length) {
        for (let i = 0; i < text.length; i++) {
          let indexOfSpecChar = diacr.indexOf(text[i]);
          if (indexOfSpecChar > -1) {
            text = text.replace(
              new RegExp(diacr[indexOfSpecChar], "g"),
              diacrSubs[indexOfSpecChar]
            );
          }
        }
      } else {
        for (let j = 0; j < diacr.length; j++) {
          text = text.replace(new RegExp(diacr[j], "g"), diacrSubs[j]);
        }
      }
      return text;
    },

    Hide_modal() {
      var body = document.body;
      body.classList.remove("hideOverflow");
      this.showModal = false;
      this.getProgramy();
    },

    filterProgramy(by) {
      window.console.log(by);
    },

    getProgramy() {
      this.$http.get("/api/admin/programs_old").then(response => {
        this.programy = response.data;
        window.console.log(response);
      });
    }
  },
  computed: {
    jmena() {
      let jmena = [];
      this.programy.forEach(program => {
        let jmeno = program.prijmeni + " " + program.jmeno;
        if (!jmena.includes(jmeno)) {
          jmena.push(jmeno);
        }
      });
      jmena.sort();
      return jmena;
    },

    programyDruhy() {
      let programy = [];
      this.programy.forEach(program => {
        if (!programy.includes(program.program_nazev)) {
          programy.push(program.program_nazev);
        }
      });
      return programy;
    },

    katedry() {
      let katedry = [];
      this.programy.forEach(program => {
        if (!katedry.includes(program.nazev_katedry)) {
          katedry.push(program.nazev_katedry);
        }
      });
      return katedry;
    },

    statusy() {
      let statusy = [];
      this.programy.forEach(program => {
        /*
            let status = { status: program.status, status_nazev: program.status_nazev }
            if (statusy.length == 0) {
                statusy.push(status)
            }
            
            statusy.forEach((st) => {
             if (!st.status_nazev.includes(status.status_nazev)) {
                    statusy.push(status)
                }  
            })

           */

        var i = statusy.findIndex(x => x.status == program.status);
        if (i <= -1) {
          statusy.push({
            status: program.status,
            status_nazev: program.status_nazev
          });
        }
      });
      return statusy;
    },

    oblasti() {
      let oblasti = [];
      this.programy.forEach(program => {
        if (!oblasti.includes(program.oblast_name)) {
          oblasti.push(program.oblast_name);
        }
      });
      return oblasti;
    },

    programyVisible() {
      let programy = [];
      this.programy.forEach(program => {
        if (program.status == this.filterStatus || this.filterStatus == "all") {
          if (
            program.prijmeni + " " + program.jmeno == this.filterJmeno ||
            this.filterJmeno == "all"
          ) {
            if (
              program.program_nazev == this.filterProgram ||
              this.filterProgram == "all"
            ) {
              if (
                program.nazev_katedry == this.filterKatedra ||
                this.filterKatedra == "all"
              ) {
                if (
                  program.oblast_name == this.filterOblast ||
                  this.filterOblast == "all"
                ) {
                  programy.push({
                    id_program: program.id_program,
                    jmeno: program.jmeno,
                    titul: program.titul,
                    prijmeni: program.prijmeni,
                    id_status: program.status,
                    id_nazev: program.id_nazev,
                    cele_jmeno: program.cele_jmeno,
                    oblast: program.oblast_name,
                    program_nazev: program.program_nazev,
                    datum_zalozeni: program.datum_zalozeni,
                    status: program.status_nazev,
                    financni_hodnota: program.financni_hodnota
                  });
                }
              }
            }
          }
        }
      });
      programy.sort(function(a, b) {
        return a.prijmeni >= b.prijmeni ? 1 : -1;
      });
      return programy;
    },

    /* excel_export(){
        let programy = []
      //  let id_nazev = ""
        this.programy.forEach((program) => {

            

               program.id_nazev= this.replaceDiacritics(program.id_nazev) 


                programy.push({
                    id_nazev:program.id_nazev,
                    cele_jmeno: program.cele_jmeno,
                    oblast:program.oblast_name,
                    program_nazev: program.program_nazev,
                    datum_zalozeni:program.datum_zalozeni,
                    status:program.status_nazev,
                    financni_hodnota: program.financni_hodnota,
                    podpora_osobniho_rustu:program.seznam_podpory
                })

            })


            

            

            return programy

        },*/

    excel_export() {
      let export_programy = JSON.parse(JSON.stringify(this.programy));

      for (var i = 0; i < export_programy.length; i++) {
        delete export_programy[i]["titul"];
        delete export_programy[i]["jmeno"];
        delete export_programy[i]["prijmeni"];
        delete export_programy[i]["nazev_katedry"];
        delete export_programy[i]["id_program"];
        delete export_programy[i]["status"];
      }

      return export_programy;
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: "/admin"
    });
    this.getProgramy();
  }
};
</script>